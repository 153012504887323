import './styles/events-feature.scss';
import EventsSlider from '../sliders/events';

type EventsFeatureProps = {
  card: {
    copy: string
    image: {
      alt_text: string
      source_url: string
    },
    image_text: string
    link_text: string
    link_url: string
    sub_title: string
    sub_title_more: string
    title: string
  },
  title
}

const EventsFeature = ({
  title,
  card,
}:EventsFeatureProps) => (
  <section className="events-feature-section section">
    <div className="container">
      <h2 className="title">{ title }</h2>
      <EventsSlider card={ card } />
    </div>
  </section>
);

export default EventsFeature;