import Link from 'components/link/link';
import Slider from 'components/sliders/slider';

import './styles/social-share.scss';


type SocialShareProps = {
  card: {
    avatar: {
      alt_text: string,
      source_url: string,
    },
    copy: string,
    image: {
      alt_text: string,
      source_url: string,
    }
    title: string,
  },
  social_copy: string,
  social_links: [],
  social_title: string,
  title: string,
}
type SocialLink = {
  image: {
    alt_text: string,
    source_url: string,
  }
  text: string,
  url: string,
}

const  SocialShare = ({
  title,
  card,
  social_title,
  social_copy,
  social_links,
}:SocialShareProps) => (
  <section className="social-share-section section">
    <div className="container">
      <h2 className="title">{ title }</h2>
      <div className="social-cards-container">
        <Slider
          card={ card }
          hasCopy
          hasTitle
          hasAvatar
          hasImage
          startingIndex={ 0 }
          activeIndex={ 0 }
          modalTitle=''
        />
        <div className="social-container card">
          <h3>{ social_title }</h3>
          <p>{ social_copy }</p>
          <ul>
            {
              social_links.map((link: SocialLink, i) => {
                return (
                  <li key={ i }>
                    <Link to={ link.url }>
                      <img
                        loading="lazy"
                        src={ link.image.source_url }
                        alt={ link.image.alt_text }
                      />
                      {link.text}
                    </Link>
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </div>
  </section>
);


export default SocialShare;