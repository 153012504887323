import Link from 'components/link/link';

const EventSlide = ({ ...props }) => {
  return (
    <div className="card">
      <div className="card-container has-shadow-large">
        <div>
          <div className="card-img-container">
            <img
              loading="lazy"
              className="card-img"
              src={props.image.source_url}
              alt={props.image.alt_text}
            />
            {props.image_text && (
              <div className="card-img-text">{props.image_text}</div>
            )}
          </div>
        </div>
        <div className="card-text-container">
          <p className="card-title">{props.title}</p>
          <p className="card-sub-title">
            {props.sub_title}
            <br />
            {props.sub_title_more}
          </p>
          <p className="card-copy">{props.copy}</p>

          <div className="button-container">
            {props.link_url ? (
              <Link
                to={props.link_url}
                className="card-link cta-button button is-rounded is-filled-green"
              >
                {props.link_text}
              </Link>
            ) : (
              <div className="button is-rounded cta-text" role="button">
                Coming Soon
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSlide;
