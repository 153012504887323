import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import FontIcon from 'components/icons';

import EventSlide from './slides/event';

const EventsSlider = ({ ...props }) => {

  const [itemIndex, setItemIndex] = useState(0);
  const totalCards = (props.card.length - 1);
  const showControls = props.card.length <= 1 ? false : true;

  return (
    <div className="slider-container slides events-slider swiper">
      <div className="slider-container-inner">
        {showControls && <button 
          type="button"             
          onClick={ () => setItemIndex(Math.max(itemIndex - 1, 0)) } >
          <FontIcon
            icon="chevron-left"
            className={ `${itemIndex == 0 ? 'wiw-icon-disable' : ''} ${props.card.length <= 1 ? 'is-invisible' : ''}` }
          />
        </button>
        }
        
        <div className="slides-container">
          <SwipeableViews
            onChangeIndex={ i => setItemIndex(i) }
            style={ { overflowX: 'clip' } }
            className="slides-swiper"
            slideStyle={ { padding: '0' } }
            springConfig={ {
              easeFunction: 'cubic-bezier(0, 0, 0.24, 0.96)',
              duration: '.8s',
              delay: '0s',
            } }
            enableMouseEvents
            index={ itemIndex }
          >
            {props.card.map((card, index) => (
              <EventSlide
                key={ index }
                { ...card }
              />
            ))}
          </SwipeableViews>
        </div>
        {showControls && <button 
          type="button"             
          onClick={ () => setItemIndex(Math.min(itemIndex + 1, totalCards)) } >          
          <FontIcon
            icon="chevron-right"
            className={ `${itemIndex == totalCards ? 'wiw-icon-disable' : ''} ${props.card.length <= 1 ? 'is-invisible' : ''}` }
          /></button>

        }
      </div>
      {showControls &&
        <div className="slides-dots">
          {new Array(props.card.length)
            .fill(undefined)
            .map((slide, index) =>
              <div
                key={ index }
                className={ `slides-dot ${index === itemIndex ? 'slides-dot-fill' : 'slides-dot-empty'}` }
              />
            )}
        </div>
      }
    </div>
  );
};

export default EventsSlider;
