import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import EventsFeature from 'components/content-blocks/events-feature';
import EventsGrid from 'components/content-blocks/events-grid';
import MinimalBlogArticles from 'components/content-blocks/minimal-blog-articles';
import SocialShare from 'components/content-blocks/social-share';
import Yoast from 'components/globals/yoast';
import { HeroDefaultSignup } from 'components/hero/default-signup';

import '../styles/events.scss';

export default function Events() {
  const data = useStaticQuery(graphql`
  {
    allWordpressPage(filter: { slug: { eq: "events-parent-page" } }) {
      edges {
        node {
          wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          acf {
            minimal_blog_articles {
              title
              more_link
              article {
                link
                image {
                  alt_text
                  source_url
                }
              }
            }
            hero {
              title
              copy
              vero {
                button_text
                modal_title
                modal_image {
                  source_url
                }
              }
              background_desktop_image {
                source_url
              }
              background_mobile_image {
                source_url
              }
            }
            social_share{
              title
              card {
                title
                copy
                avatar {
                  source_url
                  alt_text
                }
                image {
                  source_url
                  alt_text
                }
              }
              social_title
              social_copy
              social_links {
                text
                url
                image {
                  source_url
                  alt_text
                }
              }
            }
            events_feature {
              title
              card {
                title
                sub_title
                sub_title_more
                copy
                link_text
                link_url
                image {
                  alt_text
                  source_url
                }
                image_text
              }
            }
            events_grid {
              title
              events {
                title
                dates
                location
                copy
                link_url
                link_text
                image_text
                image {
                  alt_text
                  source_url
                }
                type
                ended
              }
            }
          }
        }
      }
    }
  }
  `);

  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const articles = data.allWordpressPage.edges[0].node.acf.minimal_blog_articles;
  const hero = data.allWordpressPage.edges[0].node.acf.hero;
  const socialShare = data.allWordpressPage.edges[0].node.acf.social_share;
  const eventsFeature = data.allWordpressPage.edges[0].node.acf.events_feature;
  const eventsGrid = data.allWordpressPage.edges[0].node.acf.events_grid;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="events-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroDefaultSignup { ...hero } />
        <EventsFeature { ...eventsFeature } />
        <EventsGrid { ...eventsGrid } />
        <SocialShare { ...socialShare } />
        <MinimalBlogArticles { ...articles } />
      </div>
    </Layout>
  );

}