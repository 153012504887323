import { Fragment, useState } from 'react';

import Link from 'components/link/link';
import Tabs from 'components/tabs/tabs';

import './styles/events-grid.scss';

type EventsGridProps = {
  events: Event[],
  title: string,
}
type Event = {
  copy: string,
  dates: string,
  ended: boolean,
  image: {
    alt_text: string,
    source_url: string,
  },
  image_text: string,
  link_text: string,
  link_url: string,
  location: string,
  title: string,
  type: string[],
}

const EventsGrid = ({
  title,
  events,
}:EventsGridProps) => {
  const numSet = 6; // number of cards to show per set
  const labels = ['Conference', 'Virtual Conference', 'Webinar', 'Customer Webinar'];

  // events that have ended should be shown last
  events.sort((a, b) => (a.ended === b.ended) ? 0 : a.ended ? 1 : -1);

  // filter events by event type
  const filterEvents = (label, events) => events.map(event => event.type.includes(label) ? event : null).filter(Boolean);

  // get all groupings of event types
  const tabs = labels.map(label => filterEvents(label, events));

  // add a group for all events
  labels.unshift('All Events');
  tabs.unshift(events);

  // track the number shown for each tab
  const [numShown, setNumShown] = useState(new Array(labels.length).fill(numSet));

  return (
    <section className="events-grid-section section">
      <div className="container">
        { title && <h2>{ title }</h2> }
        <Tabs label={ labels } anchorLinks>
          {tabs.map((tab, index) => {
            const slicedTab = tab.slice(0, numShown[index]);

            return (
              <Fragment key={ index }>
                {slicedTab.map((event, i) => (
                  <div key={ i } className={ `grid-card ${event.ended && 'event-ended'}` }>
                    <div className="grid-card-img-container"> 
                      <img
                        loading="lazy"
                        className="grid-card-img"
                        src={ event.image.source_url }
                        alt={ event.image.alt_text }
                      />
                      { event.image_text && <div className="grid-card-img-text">{ event.image_text }</div> }
                    </div>
                    <div className="grid-card-text-container">
                      { event.type[0] && <div className="pre-head">{ event.type[0] }</div> }
                      <h3>{ event.title }</h3>
                      <p className="copy">{ event.copy }</p>
                      <div className="sub-head">
                        { event.dates }
                        <br />
                        { event.location }
                      </div>
                      {event.ended ? (
                        <span className="event-ended-text is-rounded">Event Ended</span>
                      ) : (
                        <Fragment>
                          {event.link_url ? (
                            <Link
                              to={ event.link_url }
                              className="grid-card-link cta-button button is-rounded"
                            >
                              { event.link_text ? event.link_text : 'Learn More' }
                            </Link>
                          ) : (
                            <span className="grid-card-link cta-button button is-rounded coming-soon">Coming Soon</span>
                          )}
                        </Fragment>
                      )}
                    </div>

                  </div>
                ))}
                <button 
                  className={ `see-more button is-rounded ${numShown[index] >= tab.length ? 'is-hidden' : ''}` } 
                  onClick={ () => 
                    setNumShown(numShown.map((numShownOnTab, i) =>
                      i == index ? numShownOnTab + numSet : numShownOnTab) // increase the amount of cards shown on current tab
                    ) 
                  }
                >
                  See More
                </button>
              </Fragment>
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default EventsGrid;